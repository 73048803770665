import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppThunkDispatch, useAppSelector } from '../../../../app/hooks/redux';
import { selectClients } from '../../../../app/store/slice/clients';
import { fetchClient, updateClient } from '../../../../app/store/slice/clients/asyncTasks';
import { selectUsers } from '../../../../app/store/slice/user';


import ClientDetailComponent from '../../../../components/Clients/ClientDetail';
import Placeholder from '../../../../components/Placeholder';

function ClientDetail() {

    const params = useParams();
    const dispatch = useAppThunkDispatch();
    const { client, status } = useAppSelector(selectClients);
    const { user } = useAppSelector(selectUsers)
  
    useEffect(function () {
        dispatch(fetchClient(params.id));
    }, [dispatch, params.id]);

    function updateUserBalance(amount: number) {
        dispatch(updateClient({ id: client.id, balance: client.balance + amount }));
    }

    return (
        <Fragment>
            {
                !client || status === "loading" ?
                    <Placeholder />
                    :
                    <ClientDetailComponent client={client} user={user} updateUserBalance={updateUserBalance

                    } />
            }
        </Fragment>
    )
};

export default ClientDetail;
