import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import { formatDateTimeForUserTimeZone } from "../../../utils/format";
import { Link } from 'react-router-dom';

const OrderPrint = forwardRef<HTMLDivElement, any>((props: any, ref) => {
    const { order, payed_quotation, delivery_image_urls } = props;

    const { order_id, order_custom_id, client_custom_id, client_email, client_company_name, client_id,
        payment_type, cut_type, material_type, material_count, cut_count, thickness, thickness_unit, width, length, width_length_unit, notes, } = order;

    return (
        <div style={{ display: 'none' }}>
              <div ref={ref} className="card-body">
                <div className="d-lg-flex">
                  <h6 className="main-content-label mb-4">
                    <span className="d-flex mb-4">
                      <Link to={'/dashboard/orders'}>
                        <img
                          src={require("../../../assets/img/brand/cortenLogo.png")}
                          className="sign-favicon ht-50"
                          alt="logo"
                        />
                      </Link>
                    </span>
                  </h6>
                  <div className="ms-auto">
                    <div className="mb-1">
                      <h5 className="font-weight-bold">Cliente: {client_custom_id || client_id}</h5>
                    </div>
                  </div>   
                </div>

                <div className="row row-sm pt-2">
                  <div className="col col-lg-6">
                    <address className="fontOrangeMetric text-center">{width} {width_length_unit} Ancho </address>
                    <img
                      src={require("../../../assets/img/brand/genericImgN.png")}
                      className="sign-favicon br-5 w-60"
                    />
                  </div>
                  <div className="col col-sm-1 col-lg-1 col-m-1 d-flex align-items-center">
                    <address className="fontOrangeMetric text-center">{length} {width_length_unit} Largo</address>

                  </div>

                  <div className="col col-sm-1 col-lg-1 col-m-1">
                  </div>

                  <div className="col col-lg-4 text-start">
                    <div className="row d-flex justify-content-between">
                      <div className="col d-flex align-items-center">
                        <img
                          src={require("../../../assets/img/brand/greenMachine.png")}
                          className="ht-55"
                        />
                      </div>
                      <div className="col d-flex justify-content-center align-items-center">
                        <p className="h1 mb-4 mt-4 text-end">Orden de trabajo</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="mb-1 text-end">
                        <span className="fs-4">Cliente:</span>
                      </p>
                      <address className="fontOrange text-end">{client_company_name}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Pedido:</span>
                      </p>
                      <address className="fontOrange text-end">{order_custom_id || order_id}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tipo de corte:</span>
                      </p>
                      <address className="fontOrange text-end">{cut_type}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tipo de material:</span>
                      </p>
                      <address className="fontOrange text-end">{material_type}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Espesor:</span>
                      </p>
                      <address className="fontOrange text-end">{thickness} {thickness_unit} </address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Piezas de material:</span>
                      </p>
                      <address className="fontOrange text-end">{material_count} Piezas</address>
                    </div>
                   
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Piezas a cortar:</span>
                      </p>
                      <address className="fontOrange text-end">{cut_count} Piezas</address>
                    </div>

                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Notas:</span>
                      </p>
                      <address className="fontOrange text-end">{notes}</address>
                    </div>
                   
                    
                    {payed_quotation?.machine_scheduled_date ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Fecha a maquina:</span>
                      </p>
                      <address className="fontOrange text-end">{formatDateTimeForUserTimeZone(payed_quotation.machine_scheduled_date)}</address>
                    </div> : null}
                    
                    {payed_quotation?.machine_duration_minutes ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tiempo en maquina:</span>
                      </p>
                      <address className="fontOrange text-end">{payed_quotation.machine_duration_minutes} Minutos</address>
                    </div> : null}
                    <br />
                  </div>
                </div>
              </div>
            </div>
    );
})

export default OrderPrint;