import { User } from "../models/user";

export const roleValidation = (userGroups: Array<string>, user: User ) => {
    const groups = userGroups.map(group => group.toLocaleLowerCase());
    if (groups.includes(user?.user_group.toLocaleLowerCase())) {
        return true;
    }
    return false;
}

export const roleJobValidation = (userGroups: Array<string>, jobs: Array<string>, user: User ) => {
    const groups = userGroups.map(group => group.toLocaleLowerCase());
    const positions = jobs.map(position => position.toLocaleLowerCase());
    if (groups.includes(user?.user_group.toLocaleLowerCase()) &&  positions.includes(user?.job.toLocaleLowerCase())) {
        return true;
    }
    return false;
}