import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import { formatDateTimeForUserTimeZone } from "../../../utils/format";

const ReceptionPrint = forwardRef<HTMLDivElement, any>((props: any, ref) => {

    const { order, payed_quotation, reception_image_urls } = props;

    const { order_custom_id, client_custom_id, client_email, client_company_name,
        cut_type, material_type, material_count, cut_count, thickness, thickness_unit, width, length, width_length_unit, notes, } = order;

    function renderImages(image_urls: string[], file_paths: string[], type: string) {
        let cardItems;

        if (image_urls && file_paths) {
            return image_urls.map(image_url => {
                return (
                    <div className="col col-6">
                        <Card className="custom-card">
                            <Card.Body>
                                <div className="h-100  attached-file-grid6">
                                    <div className="pro-img-box attached-file-image">
                                        <img
                                            className="pic-1 pos-relative br-5 w-100"
                                            src={image_url}
                                            alt="attached-file"
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                );
            });
        }

        return cardItems
    }

    return (
        <div style={{ display: "none" }}>
            <div ref={ref} className="row card-body">
                <div className="col col-8">

                    <span className="d-flex ">
                        <img
                            src={require("../../../assets/img/brand/cortenLogo.png")}
                            className="sign-favicon ht-100"
                            alt="logo"
                        />
                    </span>

                    <div className="row">
                        {renderImages(reception_image_urls, order.reception_file_paths, 'reception_file_paths')}
                    </div>

                </div>
                <div className="col col-4 mt-4 px-3" style={{ borderLeft: "1px black solid" }}>
                    <span className="d-flex justify-content-end">
                        <img
                            src={require("../../../assets/img/media/reception-receipt.jpeg")}
                            className="sign-favicon ht-100 my-4 rounded"
                            alt="logo"
                        />
                    </span>
                    <div className="ms-auto">
                        <div className="mb-1">
                            <h3 className="font-weight-bold text-end">Recibo de Recepcion</h3>
                            <div className="row d-flex justify-content-between">
                                <div className="col d-flex justify-content-end">
                                    <p className="h4 mb-4 mt-4 text-end">ID Pedido: {order_custom_id}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <p className="mb-1 text-end">
                                <span className="fs-4">Fecha:</span>
                            </p>
                            <address className="fontOrange text-end">{formatDateTimeForUserTimeZone(Date())}</address>
                        </div>

                        <div className="mt-4">
                            <p className="mb-1 text-end">
                                <span className="fs-4">Cliente:</span>
                            </p>
                            <address className="fontOrange text-end">{client_company_name}</address>
                        </div>

                        <div>
                            <p className="mb-1 text-end">
                                <span className="fs-4">Pedido:</span>
                            </p>
                            <address className="fontOrange text-end">{client_custom_id}</address>
                        </div>

                        <div>
                            <p className="mb-1 text-end">
                                <span className="fs-4">Piezas de material recibido:</span>
                            </p>
                            <address className="fontOrange text-end">{material_count} Piezas</address>
                        </div>

                        <div className="mt-4">
                            <p className="mb-1 text-end">
                                <span className="fs-4">Tipo de corte:</span>
                            </p>
                            <address className="fontOrange text-end">{cut_type}</address>
                        </div>

                        <div>
                            <p className="mb-1 text-end">
                                <span className="fs-4">Tipo de material:</span>
                            </p>
                            <address className="fontOrange text-end">{material_type}</address>
                        </div>

                        <div>
                            <p className="mb-1 text-end">
                                <span className="fs-4">Espesor:</span>
                            </p>
                            <address className="fontOrange text-end">{thickness} {thickness_unit}</address>
                        </div>
                        <div className="mt-4">
                            <p className="mb-1 text-end">
                                <span className="fs-4">Largo x Ancho</span>
                            </p>
                            <address className="fontOrange text-end">{length} x {width} {width_length_unit}</address>
                        </div>
                       
                    </div>

                </div>
                <div style={{ borderTop: "1px black solid", width: "100%" }}>
                    <div className="d-flex flex-column justify-content-start align-items-start bg-light p-2">
                        <h5 className="">Observaciones de recepción:</h5>
                        {
                            order.delivery_observations ?
                                <p className="">{order.reception_observations}</p>
                                :
                                <p className=""> Ninguna Observación</p>
                        }
                    </div>
                    <div className="d-flex flex-column justify-content-start align-items-start p-2">
                        <p>El sobrante del material debe ser recogido por el cliente en caso contrario se cobrara un % por dia de alacenamiento.</p>
                        <p>Estoy de acuerdo en las condiciones que entrego el material de mi pedido.</p>

                    </div>
                    <div className="d-flex justify-content-between mt-5">
                        <div className="text-center" style={{ borderTop: "1px black solid", width: "40%" }}>Nombre y firma de cliente</div>
                        <div className="text-center" style={{ borderTop: "1px black solid", width: "40%" }}>Nombre y firma de operador</div>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default ReceptionPrint;
