import React from 'react';
import { useForm } from 'react-hook-form';
import { useAppSelector } from '../../../app/hooks/redux';

import Input from '../../Form/Input';

import { requireField, errorTypes } from '../../../utils/formValidations';
import { selectQuotations } from '../../../app/store/slice/quotations';

function OrderTransferPaymentForm(props: any) {
  const { onSubmit } = props;
  const { quotations } = useAppSelector(selectQuotations);
  const { register, handleSubmit, formState: { errors } } = useForm();


  function renderQuotations (quotations: any) {
    const name = "quotationSelected";
    const label = "Seleccione Cotizacion Pagada";
    const errorMessages =[{ type: errorTypes.required, message: 'Por favor seleccionar cotizacion' }];

    return  <div className="form-group dropdown">
              <label className="form-label fs-6">{label}</label>
              <select {...register(name, { required: requireField })} className="form-control">
              <option hidden value="">{label}</option>
                  {quotations.map(function(option: any, i: number) {
                      return <option
                                  key={`${option}`}
                                  value={typeof option === 'object' ? JSON.stringify(option) : option}>
                                  {"ID: " + option.quotation_id + " Total: " + option.price}
                              </option>
                  })}
              </select>
              {errorMessages.map(function(errorMessage: any) {
                  return errors[name] && errors[name].type === errorMessage.type && <p key={errorMessage} className="text text-danger">{errorMessage.message}</p>
              })}
          </div>
  };

  return (
      <div className="mx-auto d-block col">
        <div className="card border shadow-none card-body">
          <form className="form mx-auto d-block col" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <Input 
                  type={"number"} 
                  name={"payment_reference"} 
                  validation={{ 
                    required: requireField,
                  }} 
                  label={"Número de Referencia:"} 
                  errorMessages={[{ type: errorTypes.required, message: 'Ingrese el número de referencia de la transferencia' }]}
                  register={register} 
                  errors={errors} />
              { renderQuotations(quotations) }
              <Input 
                  type={"text"} 
                  name={"payment_extra_info"} 
                  label={"Concepto:"} 
                  errorMessages={[{ type: errorTypes.required, message: 'Ingrese el informacion extra' }]}
                  register={register} 
                  errors={errors} />    
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-block btn">Marcar como pagado</button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default OrderTransferPaymentForm;
