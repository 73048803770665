import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Header from "../../Dashboard/Header";

function OperatorDetail (props: any) {

  const { id, email, phone_number, user_group, name, department, job, disabled } = props.operator;
  
  return (
    <Fragment>
      <Header title='DETALLE DEL OPERADOR'/>
      <div className=" row row-sm">
        <div className="col col-md-12 col-lg-12">
          <div className="card custom-card">
            <div className="card-bady m-4">
              <div className="d-lg-flex">
                <h6 className="main-content-label mb-1">
                  <span className="d-flex mb-4">
                    <Link to={'/dashboard/clients'}>
                      <img
                        src={require("../../../assets/img/brand/favicon-admin-dark.png")}
                        className="sign-favicon ht-40"
                        alt="logo"
                      />
                    </Link>
                  </span>
                </h6>
                <div className="ms-auto">
                  <p className="mb-1">
                    <span className="font-weight-bold">ID del operador: {id}</span>
                  </p>
                </div>
              </div>
              <div className="row row-sm">
                <div className="col col-lg-6">
                  <p className="h3">Email:</p>
                  <address>
                    {email}
                  </address>
                </div>
                <div className="col col-lg-6 text-end">
                  <p className="h3">Detalles generales:</p>
                  <div>
                    <p className="mb-1">
                      <span className="font-weight-bold fs-5">Estatus:</span>
                    </p>
                    {
                      disabled ? 
                      <span className="badge badge-danger-transparent fs-5">Desactivado</span> :
                        
                      <span className="badge badge-success-transparent fs-5">Activo</span>
                    }
                  </div>2
                  <br />
                  <div>
                    <p className="mb-1">
                      <span className="font-weight-bold">Fecha de alta:</span>
                    </p>
                    <address>2023/23/01</address>
                  </div>
                </div>
              </div>
                              
              <div className="p-t-10">
                <div className="d-sm-flex">

                  <div className="mg-sm-r-20 mg-b-10">
                      <div className="media">
                        <div className="media-body">
                          <span><b>Nombre</b></span>
                          <div>{name}</div>
                        </div>
                      </div>
                    </div>  

                    <div className="mg-sm-r-20 mg-b-10">
                      <div className="media">
                        <div className="media-body">
                          <span><b>Teléfono</b></span>
                          <div>{phone_number}</div>
                        </div>
                      </div>
                    </div>  

                    {/* </div>
                    </div>

                  <div className="p-t-10">
                  <div className="d-sm-flex"> */}
                    <div className="mg-sm-r-20 mg-b-10">
                      <div className="media">
                        <div className="media-body">
                          <span><b>Permisos</b></span>
                          <div>{user_group}</div>
                        </div>
                      </div>
                    </div>  

                    <div className="mg-sm-r-20 mg-b-10">
                      <div className="media">
                        <div className="media-body">
                          <span><b>Departamento</b></span>
                          <div>{department}</div>
                        </div>
                      </div>
                    </div>  

                    <div className="mg-sm-r-20 mg-b-10">
                      <div className="media">
                        <div className="media-body">
                          <span><b>Posición</b></span>
                          <div>{job}</div>
                        </div>
                      </div>
                    </div>        
                </div>
              </div>

              <div className="table-responsive mg-t-40">
                {/* <DetailTable/> */}
              </div>
            </div>
            <div className="card-footer text-end">
              {/* <Link  
                to={'/dashboard/orders/:id/calendar'}
                className="btn ripple btn-primary  mb-1 me-2"
              >
                 <i className="far fa-calendar me-1"></i> Calendario
              </Link> */}
              <Link 
                to={`/dashboard/operators/${id}/update`}
                className="btn ripple btn-warning mb-1 me-2"
              >
                <i className="fa fa-edit"></i> Modificar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default OperatorDetail;
